<template>
  <el-dialog
      title="Cài đặt chế độ xem cho Camera"
      :visible.sync="showSettingCamera"
      width="900px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      top="3vh"
      @open="initialDataMethod"
  >
    <el-form
        label-width="160px"
        :model="dataInput"
        label-position="left"
        :rules="rules"
        ref="dataInput"
    >
      <el-form-item label="Giới hạn xem liên tục">
        <el-radio-group v-model="dataInput.viewLimitStatus">
          <el-radio :label="true">Có</el-radio>
          <el-radio :label="false">Không</el-radio>
        </el-radio-group>
        <span style="float: right"
        >Số phút xem liên tục &nbsp;&nbsp;&nbsp;&nbsp;
          <el-input-number
              style="width: 250px"
              v-model="dataInput.viewLimitNumber"
          ></el-input-number
          ></span>

      </el-form-item>
      <el-form-item prop="viewLimitText">
        <el-input
            v-model="dataInput.viewLimitText"
            placeholder="Nhập nội dung"
        ></el-input>
      </el-form-item>
      <el-form-item label="Khoảng thời gian xem">
        <el-checkbox
            v-model="dataInput.timeViewStatus"
        >Giới hạn khoảng thời gian xem camera không
        </el-checkbox>
      </el-form-item>
      <el-form-item label="Thời gian được xem">
        <el-row>
          <el-col :span="4">
            <div class="grid-content bg-purple">Giá trị 1</div>
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.startTime1"
                  placeholder="Bắt đầu"
                  style="width: 170px"
                  :picker-options="getTimeSelect()"
                  :clearable="false"
              >
              </el-time-select>
            </div
            >
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.endTime1"
                  style="width: 170px"
                  placeholder="Kết thúc"
                  :picker-options="getTimeSelect()"
                  :clearable="false"
              >
              </el-time-select>
            </div
            >
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content bg-purple">Giá trị 2</div>
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.startTime2"
                  placeholder="Bắt đầu"
                  style="width: 170px"
                  :picker-options="getTimeSelect()"
              >
              </el-time-select>
            </div
            >
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.endTime2"
                  style="width: 170px"
                  placeholder="Kết thúc"
                  :picker-options="getTimeSelect()"
              >
              </el-time-select>
            </div
            >
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content bg-purple">Giá trị 3</div>
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.startTime3"
                  placeholder="Bắt đầu"
                  style="width: 170px"
                  :picker-options="getTimeSelect()"
              >
              </el-time-select>
            </div
            >
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.endTime3"
                  style="width: 170px"
                  placeholder="Kết thúc"
                  :picker-options="getTimeSelect()"
              >
              </el-time-select>
            </div
            >
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content bg-purple">Giá trị 4</div>
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.startTime4"
                  placeholder="Bắt đầu"
                  style="width: 170px"
                  :picker-options="getTimeSelect()"
              >
              </el-time-select>
            </div
            >
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.endTime4"
                  style="width: 170px"
                  placeholder="Kết thúc"
                  :picker-options="getTimeSelect()"
              >
              </el-time-select>
            </div
            >
          </el-col>
        </el-row>
        <el-row style="margin-top: 10px">
          <el-col :span="4">
            <div class="grid-content bg-purple">Giá trị 5</div>
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.startTime5"
                  placeholder="Bắt đầu"
                  style="width: 170px"
                  :picker-options="getTimeSelect()"
              >
              </el-time-select>
            </div
            >
          </el-col>
          <el-col :span="8"
          >
            <div class="grid-content bg-purple">
              <el-time-select
                  v-model="dataInput.endTime5"
                  style="width: 170px"
                  placeholder="Kết thúc"
                  :picker-options="getTimeSelect()"
              >
              </el-time-select>
            </div>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item prop="timeViewText">
        <el-input v-model="dataInput.timeViewText" placeholder="Nhập nội dung"></el-input>
      </el-form-item>
      <el-form-item label="Chọn ngày trong tuần">
        <el-row>
          <el-checkbox v-model="dataInput.statusDayOfWeek" @change="handelChangeStatusDayOfWeek"
          >Giới hạn khoảng thời gian xem camera các ngày trong tuần không
          </el-checkbox>
        </el-row>
        <el-row v-if="dataInput.statusDayOfWeek">
          <el-checkbox v-model="selectAllDayOfWeek" v-if="dataInput.statusDayOfWeek" @change="handleSelectAllDayOfWeek"
          >Tất cả
          </el-checkbox>

          <el-checkbox v-model="selectDayOfWeek" v-if="dataInput.statusDayOfWeek" @change="handleSelectDayOfWeek"
          >Chọn từng ngày trong tuần
          </el-checkbox>
        </el-row>
      </el-form-item>
      <el-form-item v-if="dataInput.statusDayOfWeek" label="Ngày được xem">
        <el-row>
          <el-checkbox v-model="dataInput.statusMonday" @change="handleChangeDayOfWeek"
          >Thứ 2
          </el-checkbox>
          <el-checkbox v-model="dataInput.statusTuesday" @change="handleChangeDayOfWeek"
          >Thứ 3
          </el-checkbox>
          <el-checkbox v-model="dataInput.statusWednesday" @change="handleChangeDayOfWeek"
          >Thứ 4
          </el-checkbox>
          <el-checkbox v-model="dataInput.statusThursday" @change="handleChangeDayOfWeek"
          >Thứ 5
          </el-checkbox>
          <el-checkbox v-model="dataInput.statusFriday" @change="handleChangeDayOfWeek"
          >Thứ 6
          </el-checkbox>
          <el-checkbox v-model="dataInput.statusSaturday" @change="handleChangeDayOfWeek"
          >Thứ 7
          </el-checkbox>
          <el-checkbox v-model="dataInput.statusSunday" @change="handleChangeDayOfWeek"
          >Chủ nhật
          </el-checkbox>
        </el-row>
      </el-form-item>
      <el-form-item prop="timeViewDayOfWeekText" v-if="dataInput.statusDayOfWeek">
        <el-input v-model="dataInput.timeViewDayOfWeekText" placeholder="Nhập nội dung"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="danger" size="medium" @click="closeDialog()">
        <i class="el-icon-circle-close"/>
        <span>{{ $t("button.close") }}</span>
      </el-button>
      <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="submitForm()"
      >
        <i class="el-icon-circle-check"/>
        <span>{{ $t("button.save") }}</span>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import SupperPlusService from "@/services/SupperPlusService";
import StudentService from "@/services/StudentService";
import NotifyMessage from "@/commonFuncion/NotifyMessage";

export default {
  props: {
    showSettingCamera: {
      type: Boolean,
      default: () => false
    },
    idPlus: {
      type: Number,
      default: () => 0
    },
    idCamera: {
      type: Number,
      default: () => 0
    },
    cameraName: {
      type: String,
      default: () => ''
    },
  },
  data() {
    return {
      dataInput: {
        idUserCam: 0,
        idCamera: 0,
        nameCam: "",
        typeUser: "",
        viewLimitStatus: false,
        timeViewStatus: false,
        viewLimitNumber: 30,
        viewLimitText: "Bạn đã xem online liên tục quá lâu, Vui lòng quay lại sau!",
        timeViewText: "Bạn không thể xem camera trong thời gian này, Vui lòng quay lại sau!",
        timeViewDayOfWeekText: "Bạn không thể xem camera trong thời gian này, Vui lòng quay lại sau!",
        startTime1: "06:00",
        endTime1: "18:00",
        startTime2: null,
        endTime2: null,
        startTime3: null,
        endTime3: null,
        startTime4: null,
        endTime4: null,
        startTime5: null,
        endTime5: null,
        createNewConfigCamera: true,
        statusDayOfWeek: false,
        statusMonday: false,
        statusTuesday: false,
        statusWednesday: false,
        statusThursday: false,
        statusFriday: false,
        statusSaturday: false,
        statusSunday: false,
      },
      loadingButton: false,
      selectAllDayOfWeek: false,
      selectDayOfWeek: true,
      rules: {
        viewLimitText: [{
          required: true,
          message: "Không được để trống!",
          trigger: "change",
        }],
        timeViewText: [{
          required: true,
          message: "Không được để trống!",
          trigger: "change",
        }],
        timeViewDayOfWeekText: [{
          required: true,
          message: "Không được để trống!",
          trigger: "change",
        }],
      },
      initialDataLoaded: false
    };

  },
  methods: {
    //reset when click x
    closeDialog() {
      this.clearFormData();
      this.$emit("close");
    },
    getTimeSelect() {
      return {
        start: "00:00",
        step: "00:05",
        end: "23:55",
      };
    },
    clearFormData() {
      Object.assign(this.dataInput, {
        idUserCam: 0,
        idCamera: 0,
        nameCam: "",
        typeUser: "",
        viewLimitStatus: false,
        timeViewStatus: false,
        viewLimitNumber: 30,
        viewLimitText: "Bạn đã xem online liên tục quá lâu, Vui lòng quay lại sau!",
        timeViewText: "Bạn không thể xem camera trong thời gian này, Vui lòng quay lại sau!",
        timeViewDayOfWeekText: "Bạn không thể xem camera trong thời gian này, Vui lòng quay lại sau!",
        startTime1: "06:00",
        endTime1: "18:00",
        startTime2: null,
        endTime2: null,
        startTime3: null,
        endTime3: null,
        startTime4: null,
        endTime4: null,
        startTime5: null,
        endTime5: null,
        createNewConfigCamera: true,
        statusDayOfWeek: false,
        statusMonday: true,
        statusTuesday: true,
        statusWednesday: true,
        statusThursday: true,
        statusFriday: true,
        statusSaturday: true,
        statusSunday: true,
      });
      if (this.$refs["dataInput"]) {
        this.$refs["dataInput"].clearValidate();
      }
    },
    handelChangeStatusDayOfWeek() {
      console.log(this.dataInput.statusDayOfWeek)
      if (this.dataInput.statusDayOfWeek === false) {
        this.selectDayOfWeek = true
        this.selectAllDayOfWeek = false
        this.dataInput.statusMonday = false
        this.dataInput.statusTuesday = false
        this.dataInput.statusWednesday = false
        this.dataInput.statusThursday = false
        this.dataInput.statusFriday = false
        this.dataInput.statusSaturday = false
        this.dataInput.statusSunday = false
      }else {
        this.selectAllDayOfWeek = true
        this.handleSelectAllDayOfWeek()
      }
    },
    handleSelectAllDayOfWeek() {
      if (this.selectAllDayOfWeek === true && this.selectDayOfWeek === true) {
        this.selectDayOfWeek = false
      }
      if (this.selectAllDayOfWeek === true && this.selectDayOfWeek === false) {
        this.selectDayOfWeek = false
        this.dataInput.statusMonday = true
        this.dataInput.statusTuesday = true
        this.dataInput.statusWednesday = true
        this.dataInput.statusThursday = true
        this.dataInput.statusFriday = true
        this.dataInput.statusSaturday = true
        this.dataInput.statusSunday = true
      } else {
        this.selectDayOfWeek = true
        this.dataInput.statusMonday = false
        this.dataInput.statusTuesday = false
        this.dataInput.statusWednesday = false
        this.dataInput.statusThursday = false
        this.dataInput.statusFriday = false
        this.dataInput.statusSaturday = false
        this.dataInput.statusSunday = false
      }
    },
    handleSelectDayOfWeek() {
      if (this.selectDayOfWeek === true) {
        this.selectAllDayOfWeek = false
        this.dataInput.statusMonday = false
        this.dataInput.statusTuesday = false
        this.dataInput.statusWednesday = false
        this.dataInput.statusThursday = false
        this.dataInput.statusFriday = false
        this.dataInput.statusSaturday = false
        this.dataInput.statusSunday = false
      }
    },
    handleChangeDayOfWeek() {
      if (
          this.dataInput.statusMonday === true &&
          this.dataInput.statusTuesday === true &&
          this.dataInput.statusWednesday === true &&
          this.dataInput.statusThursday === true &&
          this.dataInput.statusFriday === true &&
          this.dataInput.statusSaturday === true &&
          this.dataInput.statusSunday === true
      ) {
        this.selectAllDayOfWeek = true
        this.selectDayOfWeek = false
      } else {
        this.selectAllDayOfWeek = false
        this.selectDayOfWeek = true
      }
    },
    submitForm() {

      this.dataInput.idUserCam = this.idPlus;
      this.dataInput.idCamera = this.idCamera;
      this.dataInput.typeUser = "plus";
      this.dataInput.nameCam = this.cameraName;
      this.$refs["dataInput"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          StudentService.saveConfigCamera(this.dataInput).then((res) => {
            res.data === true ?
                NotifyMessage.notifySuccess("Thiết lập thành công!")
                : NotifyMessage.notifyError("Thiết lập thất bại!");
            this.$parent.getVariableFromSon(true);
            this.closeDialog();
          }).catch((error) => {
            NotifyMessage.notifyError("Thiết lập thất bại!")
            console.log(error)
          }).finally(() => {
            this.loadingButton = false;
          })
        }
      });

    },

    initialDataMethod() {
      SupperPlusService.getInfoConfigCamera(this.idCamera, this.idPlus, "plus")
          .then((response) => {
            if (response.data.cameraConfigByUsers !== null) {
              this.dataInput = response.data.cameraConfigByUsers;
            }
          })
    },
  },

};
</script>

<style scoped>

</style>