var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "90%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "2vh",
            title: _vm.editInfoPlus,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formAllTabEmployee",
              attrs: {
                "label-width": "140px",
                model: _vm.formAllTabEmployee,
                "label-position": "left",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  model: {
                    value: _vm.activeTabName,
                    callback: function ($$v) {
                      _vm.activeTabName = $$v
                    },
                    expression: "activeTabName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thông tin", name: "infoTab" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                !!_vm.formAllTabEmployee
                                  .updateEmployeeMainInfoRequest.avatarView &&
                                _vm.showPicutreInitial
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "150px",
                                        height: "150px",
                                        float: "left",
                                        "margin-bottom": "10px",
                                      },
                                      attrs: {
                                        src: _vm.formAllTabEmployee
                                          .updateEmployeeMainInfoRequest
                                          .avatarView,
                                        fit: "fill",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    staticClass: "avatar-uploader",
                                    class: { hideUpload: !_vm.showUpload },
                                    attrs: {
                                      "show-file-list": true,
                                      "list-type": "picture-card",
                                      "on-change": _vm.toggleUpload,
                                      "on-remove": _vm.handleRemove,
                                      "auto-upload": false,
                                      limit: "1",
                                    },
                                  },
                                  [
                                    !!_vm.formAllTabEmployee
                                      .updateEmployeeMainInfoRequest.avatarView
                                      ? _c("span", [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "el-icon-plus avatar-uploader-icon",
                                            },
                                            [_vm._v("Cập nhật ảnh")]
                                          ),
                                        ])
                                      : _c("span", [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "el-icon-plus avatar-uploader-icon",
                                            },
                                            [_vm._v("Thêm ảnh")]
                                          ),
                                        ]),
                                  ]
                                ),
                                _c(
                                  "el-dialog",
                                  {
                                    attrs: { visible: _vm.dialogVisible1 },
                                    on: {
                                      "update:visible": function ($event) {
                                        _vm.dialogVisible1 = $event
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        width: "100%",
                                        src: _vm.dialogImageUrl,
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [
                                    _c("el-input", {
                                      staticStyle: {
                                        width: "150px",
                                        "margin-top": "10px",
                                      },
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest.code,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "code",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.code",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "group-radio-status",
                                        model: {
                                          value:
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest
                                              .employeeStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabEmployee
                                                .updateEmployeeMainInfoRequest,
                                              "employeeStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                    formAllTabEmployee.updateEmployeeMainInfoRequest.employeeStatus\n                  ",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "Đang làm" },
                                          },
                                          [_vm._v("Đang làm")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "Tạm nghỉ" },
                                          },
                                          [_vm._v("Tạm nghỉ")]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "Nghỉ làm" },
                                          },
                                          [_vm._v("Nghỉ làm")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _vm.formAllTabEmployee
                                      .updateEmployeeMainInfoRequest
                                      .employeeStatus == "Tạm nghỉ"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-left": "-120px",
                                            },
                                            attrs: {
                                              prop: "updateEmployeeMainInfoRequest.dateRetain",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "160px" },
                                              attrs: {
                                                type: "date",
                                                format: "dd-MM-yyyy",
                                                "value-format": "yyyy-MM-dd",
                                                placeholder: "Ngày tạm nghỉ",
                                              },
                                              model: {
                                                value:
                                                  _vm.formAllTabEmployee
                                                    .updateEmployeeMainInfoRequest
                                                    .dateRetain,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formAllTabEmployee
                                                      .updateEmployeeMainInfoRequest,
                                                    "dateRetain",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                      formAllTabEmployee.updateEmployeeMainInfoRequest.dateRetain\n                    ",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.formAllTabEmployee
                                      .updateEmployeeMainInfoRequest
                                      .employeeStatus == "Nghỉ làm"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-left": "-120px",
                                            },
                                            attrs: {
                                              prop: "updateEmployeeMainInfoRequest.dateLeave",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "160px" },
                                              attrs: {
                                                type: "date",
                                                format: "dd-MM-yyyy",
                                                "value-format": "yyyy-MM-dd",
                                                placeholder: "Ngày nghỉ làm",
                                              },
                                              model: {
                                                value:
                                                  _vm.formAllTabEmployee
                                                    .updateEmployeeMainInfoRequest
                                                    .dateLeave,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formAllTabEmployee
                                                      .updateEmployeeMainInfoRequest,
                                                    "dateLeave",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formAllTabEmployee.updateEmployeeMainInfoRequest.dateLeave",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("div", {
                                  staticStyle: { margin: "20px 0" },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên",
                                      prop: "updateEmployeeMainInfoRequest.fullName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .fullName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "fullName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.fullName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "updateEmployeeMainInfoRequest.birthday",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        clearable: false,
                                        format: "dd-MM-yyyy",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày sinh",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .birthday,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "birthday",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.birthday",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "updateEmployeeMainInfoRequest.gender",
                                      label: "Giới tính",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value:
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest
                                              .gender,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabEmployee
                                                .updateEmployeeMainInfoRequest,
                                              "gender",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabEmployee.updateEmployeeMainInfoRequest.gender",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Nam" } },
                                          [_vm._v("Nam")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Nữ" } },
                                          [_vm._v("Nữ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số điện thoại",
                                      prop: "updateEmployeeMainInfoRequest.phone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .phone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "phone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.phone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày vào",
                                      prop: "updateEmployeeMainInfoRequest.startDate",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "Chọn ngày vào",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .startDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "startDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.startDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Email",
                                      prop: "updateEmployeeMainInfoRequest.email",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .email,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "email",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.email",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số định danh",
                                      prop: "updateEmployeeMainInfoRequest.cmnd",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest.cmnd,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "cmnd",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.cmnd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày cấp",
                                      prop: "updateEmployeeMainInfoRequest.cmndDate",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày cấp",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .cmndDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "cmndDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.cmndDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Địa chỉ thường trú",
                                      prop: "updateEmployeeMainInfoRequest.permanentAddress",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .permanentAddress,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "permanentAddress",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                    formAllTabEmployee.updateEmployeeMainInfoRequest.permanentAddress\n                  ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Chỗ ở hiện tại",
                                      prop: "updateEmployeeMainInfoRequest.address",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .address,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "address",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Dân tộc",
                                      prop: "updateEmployeeMainInfoRequest.ethnic",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { clearable: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .ethnic,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "ethnic",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.ethnic",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Hôn nhân",
                                      prop: "updateEmployeeMainInfoRequest.marriedStatus",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          clearable: "",
                                          placeholder: "",
                                        },
                                        model: {
                                          value:
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest
                                              .marriedStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabEmployee
                                                .updateEmployeeMainInfoRequest,
                                              "marriedStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                    formAllTabEmployee.updateEmployeeMainInfoRequest.marriedStatus\n                  ",
                                        },
                                      },
                                      _vm._l(_vm.options, function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số con",
                                      prop: "updateEmployeeMainInfoRequest.numberChildren",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .numberChildren,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "numberChildren",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                    formAllTabEmployee.updateEmployeeMainInfoRequest.numberChildren\n                  ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Trình độ",
                                      prop: "updateEmployeeMainInfoRequest.educationLevel",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .educationLevel,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "educationLevel",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                    formAllTabEmployee.updateEmployeeMainInfoRequest.educationLevel\n                  ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Đối tượng",
                                      prop: "updateEmployeeMainInfoRequest.idAccountTypeList",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          placeholder: "Chọn đối tượng",
                                          clearable: "",
                                          filterable: "",
                                        },
                                        model: {
                                          value:
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest
                                              .idAccountTypeList,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabEmployee
                                                .updateEmployeeMainInfoRequest,
                                              "idAccountTypeList",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                    formAllTabEmployee.updateEmployeeMainInfoRequest.idAccountTypeList\n                  ",
                                        },
                                      },
                                      _vm._l(
                                        _vm.accountTypeList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.id,
                                            attrs: {
                                              value: item.id,
                                              label: item.name,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày ký hợp đồng",
                                      prop: "updateEmployeeMainInfoRequest.contractDate",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "Ngày ký hợp đồng",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .contractDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "contractDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "\n                    formAllTabEmployee.updateEmployeeMainInfoRequest.contractDate\n                  ",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày hết hạn",
                                      prop: "updateEmployeeMainInfoRequest.endDate",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        placeholder: "Ngày hết hạn",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest
                                            .endDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "endDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.endDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ghi chú",
                                      prop: "updateEmployeeMainInfoRequest.note",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "100%" },
                                      attrs: { type: "textarea", rows: 1 },
                                      model: {
                                        value:
                                          _vm.formAllTabEmployee
                                            .updateEmployeeMainInfoRequest.note,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabEmployee
                                              .updateEmployeeMainInfoRequest,
                                            "note",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabEmployee.updateEmployeeMainInfoRequest.note",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "App Plus" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table-icon-1",
                          attrs: {
                            border: "",
                            data: _vm.plusIconAppUpdate.plusIconUpdate1,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              width: "50",
                              type: "index",
                              label: "STT",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "iconName",
                              "min-width": "130",
                              label: "Chức năng",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Hiển thị", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      model: {
                                        value: scope.row.statusShow,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "statusShow", $$v)
                                        },
                                        expression: "scope.row.statusShow",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Mở khóa", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.rootLockStatus
                                      ? _c("el-checkbox", {
                                          model: {
                                            value: scope.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "status", $$v)
                                            },
                                            expression: "scope.row.status",
                                          },
                                        })
                                      : _c("el-checkbox", {
                                          attrs: { disabled: "" },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          staticClass: "table-icon-2",
                          attrs: {
                            border: "",
                            data: _vm.plusIconAppUpdate.plusIconUpdate2,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              width: "50",
                              type: "index",
                              label: "STT",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "iconName",
                              "min-width": "130",
                              label: "Chức năng",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Hiển thị", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      model: {
                                        value: scope.row.statusShow,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "statusShow", $$v)
                                        },
                                        expression: "scope.row.statusShow",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "Mở khóa", align: "center" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.rootLockStatus
                                      ? _c("el-checkbox", {
                                          model: {
                                            value: scope.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "status", $$v)
                                            },
                                            expression: "scope.row.status",
                                          },
                                        })
                                      : _c("el-checkbox", {
                                          attrs: { disabled: "" },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showButtonClick
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: "Cấu hình Cam",
                            name: "commonConfig",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { offset: 7 } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "multipleTable",
                                      staticStyle: {
                                        width: "700px",
                                        "margin-top": "10px",
                                      },
                                      attrs: {
                                        "element-loading-spinner":
                                          "el-icon-loading",
                                        "element-loading-background":
                                          "rgba(255,255,255, 0)",
                                        data: _vm.plusCamList,
                                        "highlight-current-row": "",
                                        "header-cell-style":
                                          _vm.tableHeaderColor,
                                        border: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "index",
                                          label: "STT",
                                          width: "50",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "cameraName",
                                          label: "Tên cam",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "description",
                                          label: "Trạng thái",
                                          width: "100px",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "checkbox-container",
                                                    },
                                                    [
                                                      _c("el-checkbox", {
                                                        model: {
                                                          value:
                                                            scope.row.active,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              scope.row,
                                                              "active",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "scope.row.active",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1960651438
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Tác vụ",
                                          width: "120",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          !scope.row.active,
                                                        size: "mini",
                                                        type: "success",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSettingCame(
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Thiết lập\n                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3803529439
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showButtonClick
            ? _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialogByButton("formAllTabEmployee")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formAllTabEmployee")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("CamSettingPlusDialog", {
        attrs: {
          showSettingCamera: _vm.showSettingCamera,
          idPlus: _vm.idPlus,
          idCamera: _vm.idCamera,
          cameraName: _vm.cameraName,
        },
        on: { close: _vm.handleCloseSettingCameraDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }